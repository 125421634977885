.full-height {
    min-height: 100vh;
}
.bg-shade {
    background: rgb(68 72 76 / 60%);
    border-radius: 1em;
}
.font-italic {
    font-style: italic;
}

@media (min-width: 1400px) {
    .container-md {
        max-width: 70em !important;
    }
}

